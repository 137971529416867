import { setLs } from "../../../utils/localStorage"
import { RemoveFromCartAction, State } from "../../types/checkoutTypes"

export const removeFromCart = (action: RemoveFromCartAction, state: State) => {
  const { cartItemId } = action.payload
  const updatedCartItems = state.cartItems.filter(
    item => item.productId !== cartItemId
  )

  // Persist to localstorage

  setLs("cartItems", updatedCartItems)

  return {
    ...state,
    cartItems: updatedCartItems,
  }
}
