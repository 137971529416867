/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react"
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles"
import theme from "./src/material-ui/theme"
import { AuthContextProvider } from "./src/contexts/authContext"
import { CheckoutProvider } from "./src/checkout/state"

export const wrapRootElement = ({ element }) => {
  return (
    <StyledEngineProvider injectFirst>
      (
      <ThemeProvider theme={theme}>
        <CheckoutProvider>
          <AuthContextProvider>{element}</AuthContextProvider>
        </CheckoutProvider>
      </ThemeProvider>
      )
    </StyledEngineProvider>
  )
}
