/**
 * This file holds all actions related to checkout and creates a
 * reducer to be imported into the CheckoutContext
 */

import { calcCartSubtotal, calcCartTotal } from "../shared/calcStateItems"
import { State, Action, CheckoutActionKind } from "../types/checkoutTypes"
import {
  addToCart,
  decrementCartItem,
  incrementCartItem,
  removeFromCart,
} from "./actions"

const updateTotals = (state: State): State => {
  const total = calcCartTotal(
    state.cartItems,
    state.currency,
    state.couponDiscountDays,
    state.taxes,
    state.shipping,
    state.couponDiscount
  )
  const subTotal = calcCartSubtotal(
    state.cartItems,
    state.currency,
    state.couponDiscountDays
  )

  return { ...state, total, subTotal }
}
export const checkoutReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case CheckoutActionKind.GO_TO_STEP: {
      return { ...state, currentStep: action.payload.stepNumber }
    }
    case CheckoutActionKind.SET_SHIPPING: {
      return updateTotals({ ...state, shipping: action.payload.amount })
    }
    case CheckoutActionKind.SET_TAXES: {
      return updateTotals({ ...state, taxes: action.payload.amount })
    }
    case CheckoutActionKind.SET_SHIPPING_NOTES: {
      return { ...state, shippingNotes: action.payload.notes }
    }
    case CheckoutActionKind.ADD_TO_CART: {
      return updateTotals(addToCart(action, state))
    }
    case CheckoutActionKind.REMOVE_FROM_CART: {
      return updateTotals(removeFromCart(action, state))
    }
    case CheckoutActionKind.INCREMENT_CART_ITEM: {
      return updateTotals(incrementCartItem(action, state))
    }
    case CheckoutActionKind.DECREMENT_CART_ITEM: {
      return updateTotals(decrementCartItem(action, state))
    }
    case CheckoutActionKind.CLEAR_CART: {
      return {
        ...state,
        cartItems: [],
        shipping: undefined,
        taxes: undefined,
        couponDiscount: 0,
        couponCode: "",
        couponDescription: "",
        total: 0,
        subTotal: 0,
      }
    }
    case CheckoutActionKind.CLEAR_COUPONS: {
      return updateTotals({
        ...state,
        couponDiscount: 0,
        couponDiscountDays: 0,
        couponCode: "",
        couponDescription: "",
      })
    }
    case CheckoutActionKind.SET_COUNTRY: {
      return { ...state, country: action.payload.country }
    }
    case CheckoutActionKind.SET_COUNTRY_INVENTORY: {
      return { ...state, countryInventory: action.payload.country }
    }
    case CheckoutActionKind.SET_CURRENCY: {
      const { currency } = action.payload

      return updateTotals({
        ...state,
        currency,
      })
    }

    case CheckoutActionKind.SET_COUPON_DISCOUNT: {
      return updateTotals({ ...state, couponDiscount: action.payload.amount })
    }
    case CheckoutActionKind.SET_COUPON_DISCOUNT_DAYS: {
      return updateTotals({ ...state, couponDiscountDays: action.payload.days })
    }
    case CheckoutActionKind.SET_COUPON_CODE: {
      return { ...state, couponCode: action.payload.code }
    }
    case CheckoutActionKind.SET_COUPON_DESCRIPTION: {
      return { ...state, couponDescription: action.payload.description }
    }
    case CheckoutActionKind.TOGGLE_GIFT_ORDER: {
      return { ...state, isGift: !state.isGift }
    }
    default: {
      return state
    }
  }
}
