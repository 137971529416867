import { createTheme, adaptV4Theme } from "@mui/material/styles";

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: "#1D99B8",
    },
    secondary: {
      main: "#5c38bd",
    },
  },
  typography: {
    h1: {
      fontFamily: "var(--font-family-header)",
    },
    h2: {
      fontFamily: "var(--font-family-header)",
    },
    h3: {
      fontFamily: "var(--font-family-header)",
    },
    h4: {
      fontFamily: "var(--font-family-header)",
    },
    h5: {
      fontFamily: "var(--font-family-header)",
    },
  },
}))

export default theme
