import { useStaticQuery, graphql } from "gatsby"
import { Product } from "../ts/interfaces"
import { getCartProduct, getStripeId } from "../utils/stripejs"

export const useProducts = () => {
  const { allSensaiProduct } = useStaticQuery(
    graphql`
      query {
        allSensaiProduct {
          nodes {
            id
            isSubscription
            subscriptionPeriod
            name
            stripeId
            stripeTestId
            slug
            subHeading
            description
            shortDescription
            featuredImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
            CAD_Discount
            USD_Discount
            isBilledMonthly
            isBilledYearly
            priceHeading

            gallery {
              url
              imageFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    `
  )

  allSensaiProduct.nodes.forEach((p: Product) => {
    const stripeId = getStripeId(p.stripeId, p.stripeTestId)
    const stripeProduct = getCartProduct(stripeId)

    if (stripeProduct) {
      p.CAD_List_Price =
        (stripeProduct?.prices?.cad?.unit_amount ?? 0) + p.CAD_Discount
      p.USD_List_Price =
        (stripeProduct?.prices?.usd?.unit_amount ?? 0) + p.USD_Discount
    } else {
      p.CAD_List_Price = p.CAD_Discount
      p.USD_List_Price = p.USD_Discount
    }
  })

  return allSensaiProduct.nodes as [Product]
}
