import { AddToCartAction, State } from "../../types/checkoutTypes"
import { getUpdatedCartItems } from "../../shared"
import { setLs } from "../../../utils/localStorage"

export const addToCart = (action: AddToCartAction, state: State): State => {
  const updatedCartItems = getUpdatedCartItems(
    action.payload.id,
    action.payload.quantity,
    state.cartItems
  )
  setLs("cartItems", updatedCartItems)

  return {
    ...state,
    cartItems: updatedCartItems,
  }
}
