import { IncrementCartItemAction, State } from "../../types/checkoutTypes"
import { setLs } from "../../../utils/localStorage"

export const incrementCartItem = (
  action: IncrementCartItemAction,
  state: State
) => {
  const updatedCartItems = state.cartItems.map(item => {
    if (item.productId === action.payload.cartItemId) {
      const newQuantitiy = item.quantity + 1
      return { productId: item.productId, quantity: newQuantitiy }
    } else {
      return item
    }
  })

  // Persist to localstorage
  setLs("cartItems", updatedCartItems)

  return {
    ...state,
    cartItems: updatedCartItems,
  }
}
