import { setLs } from "../../../utils/localStorage"
import { DecrementCartItemAction, State } from "../../types/checkoutTypes"

export const decrementCartItem = (
  action: DecrementCartItemAction,
  state: State
) => {
  const { cartItemId } = action.payload

  const updatedCartItems = state.cartItems.map(item => {
    if (item.productId === cartItemId) {
      const newQuantitiy = item.quantity - 1
      return { productId: item.productId, quantity: newQuantitiy }
    } else {
      return item
    }
  })

  setLs("cartItems", updatedCartItems)

  return {
    ...state,
    cartItems: updatedCartItems,
  }
}
